import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CampsComponent } from './camps/camps.component';
import { JvcalendarComponent } from './jvcalendar/jvcalendar.component';
import { JvgameComponent } from './jvgame/jvgame.component';
import { VarsitycalendarComponent } from './varsitycalendar/varsitycalendar.component';
import { VarsitygameComponent } from './varsitygame/varsitygame.component';
import { MaterialModule } from '../shared/material.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
  ],
  declarations: [
    CampsComponent,
    JvcalendarComponent,
    JvgameComponent,
    VarsitycalendarComponent,
    VarsitygameComponent,
  ],
})
export class ScheduleModule { }
