import { Component } from '@angular/core';
@Component({
    selector: 'app-sponsor',
    templateUrl: './sponsor.component.html',
    styleUrl: './sponsor.component.scss',
    standalone: false
})
export class SponsorComponent {

}
