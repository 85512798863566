import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IRoster } from '../roster';
import { RosterService } from '../roster.service';

@Component({
    selector: 'app-rosterjv',
    templateUrl: './rosterjv.component.html',
    styleUrl: './rosterjv.component.scss',
    standalone: false
})

export class RosterjvComponent implements OnInit {
  errorMessage: any;
  displayedColumns: string[] = ['jersey', 'name', 'position', 'grade', 'height', 'weight', 'image', 'hUDL'];
  roster_dataSource = new MatTableDataSource<IRoster>()

  constructor(
    private rosterService: RosterService,
  ) { }

  ngOnInit(): void {
    this.rosterService.getJVRoster().subscribe({
      next: (roster_dataSource) => (this.roster_dataSource.data = roster_dataSource),
      error: (err) => (this.errorMessage = err),
    });
  }

}
