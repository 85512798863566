import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

import { IRoster } from './roster';

@Injectable(
  { providedIn: 'root' }
)
export class RosterService {
  constructor(private http: HttpClient) { }

  getVarsityRoster(): Observable<IRoster[]> {
    return this.http
      .get<IRoster[]>('/assets/data/2024/varsityroster.json')
      .pipe(
        tap((data: any) => console.log('API Data - Varsity Roster:', JSON.stringify(data))),
      )
  }
  getJVRoster(): Observable<IRoster[]> {
    return this.http
      .get<IRoster[]>('/assets/data/2024/jvroster.json')
      .pipe(
        tap((data: any) => console.log('API Data - JV Roster:', JSON.stringify(data))),
      )
  }
  handleError(handleError: any): any {
    throw new Error('Method not implemented.');
  }
}
