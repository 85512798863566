import { Component } from '@angular/core';
@Component({
    selector: 'app-reservedseating',
    templateUrl: './reservedseating.component.html',
    styleUrl: './reservedseating.component.scss',
    standalone: false
})
export class ReservedseatingComponent {

}
