import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './shared/core.module';
import { MaterialModule } from './shared/material.module';
import { RosterModule } from './roster/roster.module';
import { SharedModule } from '@coreui/angular';
import { ScheduleModule } from './schedule/schedule.module';

import { AlertModule, CarouselModule } from '@coreui/angular';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth.interceptor';

import { AboutComponent } from './about/about.component';
import { AlumniComponent } from './alumni/alumni.component';
import { CoachingStaffComponent } from './coachingstaff/coachingstaff.component';
import { CoachBioDialogComponent } from './coachingstaff/coach-bio-dialog.component';
import { DonateComponent } from './donate/donate.component';
import { HomeComponent } from './home/home.component';
import { LinksComponent } from './links/links.component';
import { ReservedseatingComponent } from './reservedseating/reservedseating.component';
import { SponsorComponent } from './sponsor/sponsor.component';
import { SponsorlistComponent } from './sponsorlist/sponsorlist.component';
import { JvcalendarComponent } from './schedule/jvcalendar/jvcalendar.component';
import { VarsitycalendarComponent } from './schedule/varsitycalendar/varsitycalendar.component';

@NgModule({
  //This is where you list the components, directives, and pipes that are part of this module.
  //Any component, directive, or pipe declared here can be used within the templates of the components that are declared in this same module.
  declarations: [
    AppComponent,
    AboutComponent,
    AlumniComponent,
    CoachBioDialogComponent,
    CoachingStaffComponent,
    DonateComponent,
    HomeComponent,
    LinksComponent,
    ReservedseatingComponent,
    SponsorComponent,
    SponsorlistComponent,
  ],
  //
  //This is where you specify the root component that Angular creates and inserts into the index.html host web page.
  imports: [
    AlertModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CarouselModule,
    CoreModule,
    MaterialModule,
    RosterModule,
    SharedModule,
    ScheduleModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent]

})

export class AppModule { }
