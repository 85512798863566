import { Component, OnInit, ViewChild } from '@angular/core';
//import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { IRoster } from '../roster';
import { RosterService } from '../roster.service';
@Component({
  selector: 'app-rostervarsity',
  templateUrl: './rostervarsity.component.html',
  styleUrls: ['./rostervarsity.component.scss'],
  standalone: false
})

export class RostervarsityComponent implements OnInit {
  errorMessage: any;
  displayedColumns: string[] = ['jersey', 'name', 'position', 'grade', 'height', 'weight', 'image', 'hUDL'];
  roster_dataSource = new MatTableDataSource<IRoster>()

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort

  constructor(
    private rosterService: RosterService,
    //private route: ActivatedRoute
  ) {
    //console.log('rostervarsity component constructor called');

  }

  ngOnInit(): void {
    this.rosterService.getVarsityRoster().subscribe({
      next: (roster_dataSource) => (this.roster_dataSource.data = roster_dataSource),
      error: (err) => (
        //console.error('rostervarsity component error:',err);
        this.errorMessage = this.errorMessage
      ),
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event?.target as HTMLInputElement).value;
    this.roster_dataSource.filter = filterValue.trim().toLowerCase();
  }
  ngAfterViewInit(): void {
    this.roster_dataSource.sort = this.sort;
    this.roster_dataSource.paginator = this.paginator;
  }
}
