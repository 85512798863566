<div class="container text-center bg-grey">
  <div class="row">
    <h3 class="w-100 page-title d-flex justify-content-center align-items-center flex-wrap">
      <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
      <span class="mx-2 text-center shrink-text">
        COACHING STAFF
      </span>
      <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
    </h3>
  </div>
  <div class="row">
    <div *ngFor="let coach of coachingstaff_dataSource.data" class="col-sm-6 col-md-3 col-lg-2"
      style="text-align: center;">
      <img src="{{coach.image}}" class="rounded img-fluid mw-100 clickable-image" alt="Wilsonville {{coach.role}} {{coach.name}}"
        width="80%" (click)="openDialog(coach)"/>
      <br />{{coach.name}}
      <div class="small">
        <div *ngFor="let role of coach.role">{{ role }}</div>
        <button mat-button (click)="openDialog(coach)">View Bio</button>
      </div>
      <br />
    </div>
  </div>
</div>
