import { Component } from '@angular/core';

@Component({
    selector: 'app-links',
    templateUrl: './links.component.html',
    styleUrl: './links.component.scss',
    standalone: false
})
export class LinksComponent {

  links = [
    { url: 'https://www.x.com/wvhs_football', image: '/assets/images/links/x-logo-white.webp', alt: 'Wilsonville Football on X/Twitter', label: 'Wilsonville Football on X/Twitter', imageDisplayWidth: '100' },
    { url: 'https://www.facebook.com/WilsonvilleFootball', image: '/assets/images/links/facebook-logo-secondary.webp', alt: 'Wilsonville Football on Facebook', label: 'Wilsonville Football on Facebook', imageDisplayWidth: '100' },
    { url: 'https://www.instagram.com/wvhs_football', image: '/assets/images/links/instagram-logo.webp', alt: 'Wilsonville Football on Instagram', label: 'Wilsonville Football on Instagram', imageDisplayWidth: '100' },
    { url: 'https://michael-williams.smugmug.com/', image: '/assets/images/links/triplem-logo.webp', alt: 'Triple M Photography', label: 'Triple M Photography', imageDisplayWidth: '200' },
    { url: 'https://gmartman.smugmug.com/Sports/High-School', image: '/assets/images/links/gregartmanphotography-logo.webp', alt: 'Greg Artman Photography', label: 'Greg Artman Photography', imageDisplayWidth: '200' },
    { url: 'https://www.aktivate.com/', image: '/assets/images/links/aktivate-logo.webp', alt: 'Aktivate', label: 'Aktivate', imageDisplayWidth: '200' },
    { url: 'https://www.maxpreps.com/high-schools/wilsonville-wildcats-(wilsonville,or)/football/home.htm', image: '/assets/images/links/maxpreps-logo.webp', alt: 'Max Preps', label: 'Max Preps', imageDisplayWidth: '200' },
    { url: 'https://247sports.com', image: '/assets/images/links/247sports-logo.webp', alt: '247 Sports', label: '247 Sports', imageDisplayWidth: '200' },
    { url: 'https://www.hudl.com/team/22303/highlights/70995817', image: '/assets/images/links/hudl-logo.webp', alt: 'Wilsonville Football on HUDL', label: 'Wilsonville Football on HUDL', imageDisplayWidth: '200' },
    { url: 'https://www.wlwv.k12.or.us/Domain/19', image: '/assets/images/links/wvhs-logo.webp', alt: 'Wilsonville High School Website', label: 'Wilsonville High School Website', imageDisplayWidth: '100' },
    { url: 'https://www.osaa.org/activities/fbl/schedules', image: '/assets/images/links/osaa-logo.webp', alt: 'OSAA', label: 'OSAA', imageDisplayWidth: '100' },
  ];
}
