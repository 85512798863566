import { Component, OnInit, ViewChild } from '@angular/core';
//import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { ICampSchedule } from '../campschedule';
import { ScheduleService } from '../schedule.service';


@Component({
  selector: 'app-camps',
  templateUrl: './camps.component.html',
  styleUrl: './camps.component.scss',
  standalone: false
})
export class CampsComponent implements OnInit {
  pageTitle: string = 'CAMP SCHEDULE';
  errorMessage: any;
  //displayedColumns: string[] = ['date', 'school', 'name', 'link', 'date', 'location', 'cost', 'position', 'enteringgrades', 'note'];
  displayedColumns: string[] = ['date', 'logo', 'name', 'location']

  schedulecamp_dataSource = new MatTableDataSource<ICampSchedule>()

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort

  constructor(
    private scheduleService: ScheduleService,
    //private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.scheduleService.getCampSchedule().subscribe({
      next: (schedulecamp_dataSource) => (this.schedulecamp_dataSource.data = schedulecamp_dataSource),
      error: (err) => (this.errorMessage = err),
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event?.target as HTMLInputElement).value;
    this.schedulecamp_dataSource.filter = filterValue.trim().toLowerCase();
  }
  ngAfterViewInit(): void {
    //  this.sort.sort(({ id:'id', start: 'asc'}) as MatSortable);  // sets the default sort order
    this.schedulecamp_dataSource.sort = this.sort;
    this.schedulecamp_dataSource.paginator = this.paginator;
    // //throw new Error('Method not implemented.');
  }

}
