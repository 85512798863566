import { Component } from '@angular/core';

@Component({
    selector: 'app-jvcalendar',
    templateUrl: './jvcalendar.component.html',
    styleUrl: './jvcalendar.component.scss',
    standalone: false
})
export class JvcalendarComponent {

}
