import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class FooterComponent {
  currentApplicationVersion = environment.appVersion;
}
