import { Component, inject, ViewChild } from '@angular/core';
//import { ActivatedRoute } from '@angular/router';
import { ICoachingStaff } from './coachingstaff';
import { CoachingStaffService } from './coachingstaff.service';
import { CoachBioDialogComponent } from './coach-bio-dialog.component';

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-coachingstaff',
    templateUrl: './coachingstaff.component.html',
    styleUrls: ['./coachingstaff.component.scss'],
    standalone: false
})

export class CoachingStaffComponent {
  constructor(
    private coachingStaffService: CoachingStaffService,
    //private route: ActivatedRoute
  ) { }

  readonly dialog = inject(MatDialog);
  errorMessage: any;
  displayedColumns: string[] = ['name', 'role', 'image', 'bio'];

  coachingstaff_dataSource = new MatTableDataSource<ICoachingStaff>()

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort

  openDialog(coach: ICoachingStaff) {
    this.dialog.open(CoachBioDialogComponent, {
      data: coach,
      width: '600px',
      height: '400px'
    }).afterClosed().subscribe(result => {
      console.log('Dialog closed:', result);
    });
  }


  ngOnInit(): void {
    this.coachingStaffService.getCoachingStaff().subscribe({
      next: (coachingstaff_dataSource) => (this.coachingstaff_dataSource.data = coachingstaff_dataSource),
      error: (err) => (this.errorMessage = err),
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event?.target as HTMLInputElement).value;
    this.coachingstaff_dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit(): void {
    this.coachingstaff_dataSource.sort = this.sort;
    this.coachingstaff_dataSource.paginator = this.paginator;
  }
}


