<div class="container bg-grey">
  <div class="row">
    <h3 class="w-100 page-title d-flex justify-content-center align-items-center flex-wrap">
      <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
      <span class="mx-2 text-center shrink-text">
        FOOTBALL CAMPS
      </span>
      <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
    </h3>
    <table mat-table [dataSource]="schedulecamp_dataSource" matSort>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef class="text-center align-middle"
          style="color: white; background-color: #222f3d;">DATE</th>
        <td mat-cell *matCellDef="let element" class="text-center align-middle">
          {{ element.date }}
        </td>
      </ng-container>
      <ng-container matColumnDef="logo">
        <th mat-header-cell *matHeaderCellDef class="text-center align-middle"
          style="color: white; background-color: #222f3d;"></th>
        <td mat-cell *matCellDef="let element" class="text-center align-middle">
          <img src="/assets/images/colleges/{{ element.logo }}" alt="{{ element.logo }}"
            style="width: 50px; height: 50px" />
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="text-center align-middle"
          style="color: white; background-color: #222f3d;">CAMP NAME</th>
        <td mat-cell *matCellDef="let element" class="text-center align-middle">
          <a href="{{ element.link }}" target="_blank">
            {{ element.name }}
          </a>
          <br />{{ element.positions }}
          <br />
        </td>
      </ng-container>
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef class="text-center align-middle"
          style="color: white; background-color: #222f3d;">LOCATION</th>
        <td mat-cell *matCellDef="let element" class="text-center align-middle">
          {{ element.location }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter</td>
      </tr>
    </table>




  </div>
</div>