import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ISchedule } from './schedule';
import { ICampSchedule } from './campschedule';

@Injectable(
  {providedIn:'root'}
)
export class ScheduleService {
  constructor(private http: HttpClient) { }

  getVarsityGameSchedule(): Observable<ISchedule[]> {
    return this.http
      .get<ISchedule[]>('/assets/data/2024/varsityschedule.json')
      .pipe(
        tap((data: any) => console.log('API Data - Varsity Schedule:', JSON.stringify(data))),
      )
  }
  getJVGameSchedule(): Observable<ISchedule[]> {
    return this.http
      .get<ISchedule[]>('/assets/data/2024/jvschedule.json')
      .pipe(
        tap((data: any) => console.log('API Data - JV Schedule:', JSON.stringify(data))),
      )
  }
  getCampSchedule(): Observable<ICampSchedule[]> {
    return this.http
      .get<ICampSchedule[]>('/assets/data/2025/campschedule.json')
      .pipe(
        tap((data: any) => console.log('API Data - Camp Schedule:', JSON.stringify(data))),
      )
  }
  handleError(handleError: any): any {
    throw new Error('Method not implemented.');
  }
}
