import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'coach-bio-dialog',
    templateUrl: 'coach-bio-dialog.component.html',
    styleUrls: ['coach-bio-dialog.component.scss'],
    standalone: false
})

export class CoachBioDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CoachBioDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public coach: any
  ) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
