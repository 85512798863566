import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RostervarsityComponent } from './rostervarsity/rostervarsity.component';
import { RosterjvComponent } from './rosterjv/rosterjv.component';

@NgModule({
  imports: [
  CommonModule // must be here for *ngFor to work
  ],
  declarations: [
    RostervarsityComponent,
    RosterjvComponent
  ],
})
export class RosterModule { }
