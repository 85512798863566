import { Component, OnInit, ViewChild } from '@angular/core';
//import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { ISchedule } from '../schedule';
import { ScheduleService } from '../schedule.service';

@Component({
  selector: 'app-varsitygame',
  templateUrl: './varsitygame.component.html',
  styleUrls: ['./varsitygame.component.scss'],
  standalone: false
})

export class VarsitygameComponent implements OnInit {
  pageTitle: string = 'GAME SCHEDULE';
  errorMessage: any;
  displayedColumns: string[] = ['date', 'opponentSchool', 'score', 'location', 'note'];
  schedule_dataSource = new MatTableDataSource<ISchedule>()

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort

  constructor(
    private scheduleService: ScheduleService,
    //private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.scheduleService.getVarsityGameSchedule().subscribe({
      next: (schedule_dataSource) => (this.schedule_dataSource.data = schedule_dataSource),
      error: (err) => (this.errorMessage = err),
    });
  }

  ngAfterViewInit(): void {
    this.schedule_dataSource.sort = this.sort;
    this.schedule_dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event?.target as HTMLInputElement).value;
    this.schedule_dataSource.filter = filterValue.trim().toLowerCase();
  }
}
