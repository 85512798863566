<h2 mat-dialog-title class="text-center title">{{ coach.name }}</h2>
<mat-dialog-content class="text-center bg-grey">
  <div class="role">{{ coach.role }}</div>
  <img src="{{ coach.image }}" class="rounded img-fluid mw-100 coach-image"
    alt="Wilsonville {{ coach.role }} {{ coach.name }}" width="120" />
  <p class="bio-text">{{ coach.bio }}</p>
</mat-dialog-content>
<mat-dialog-actions class="text-center bg-darknavyblue">
  <button (click)="closeDialog()" class="btn btn-primary">Close</button>


</mat-dialog-actions>