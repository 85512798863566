{
  "name": "wilsonville-football",
  "version": "3.4.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "serve:prod": "ng serve --configuration=production",
    "prebuild:dev": "npm --no-git-tag-version version patch",
    "build:dev": "ng build --configuration development",
    "prebuild:prod": "npm --no-git-tag-version version patch",
    "build:prod": "ng build --configuration production",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "build:stats": "ng build --configuration production --stats-json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.6",
    "@angular/cdk": "^19.1.5",
    "@angular/common": "^19.1.6",
    "@angular/compiler": "^19.1.6",
    "@angular/core": "^19.1.6",
    "@angular/forms": "^19.1.6",
    "@angular/material": "^19.1.5",
    "@angular/platform-browser": "^19.1.6",
    "@angular/platform-browser-dynamic": "^19.1.6",
    "@angular/router": "^19.1.6",
    "@coreui/angular": "^5.3.16",
    "@coreui/coreui": "^5.2.0",
    "@coreui/icons-angular": "^5.3.16",
    "@popperjs/core": "~2.11.8",
    "bootstrap": "^5.3.3",
    "rxjs": "~7.8.1",
    "sass": "^1.77.8",
    "tslib": "^2.6.3",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.7",
    "@angular/cli": "~19.1.7",
    "@angular/compiler-cli": "^19.1.6",
    "@playwright/test": "^1.46.0",
    "@types/bootstrap": "^5.2.10",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^22.2.0",
    "jasmine-core": "~5.2.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "source-map-explorer": "^2.5.3",
    "typescript": "5.6",
    "webpack-bundle-analyzer": "^4.10.2"
  }
}
