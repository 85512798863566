import { Component } from '@angular/core';
import { AlumniService } from './alumni.service';

@Component({
    selector: 'app-alumni',
    templateUrl: './alumni.component.html',
    styleUrl: './alumni.component.scss',
    standalone: false
})
export class AlumniComponent {
  alumniProfiles: any[] = [];
  constructor(private alumniService: AlumniService) { }

  ngOnInit() {
    this.alumniService.getAlumniData().subscribe(data => {
      this.alumniProfiles = data
    });
  }

}
