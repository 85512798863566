import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ICoachingStaff } from './coachingstaff';

@Injectable(
  { providedIn: 'root' }
)
export class CoachingStaffService {
  constructor(private http: HttpClient) { }

  getCoachingStaff(): Observable<ICoachingStaff[]> {
    return this.http
      .get<ICoachingStaff[]>('/assets/data/2024/coachingstaff.json')
      .pipe(
        tap((data: any) => console.log('API Data - Coaching Staff:', JSON.stringify(data))),
      )
  }
  handleError(handleError: any): any {
    throw new Error('Method not implemented.');
  }
}
