import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { AlumniComponent } from './alumni/alumni.component';
import { CampsComponent } from './schedule/camps/camps.component';
import { CoachingStaffComponent } from './coachingstaff/coachingstaff.component';
import { JvcalendarComponent } from './schedule/jvcalendar/jvcalendar.component';
import { JvgameComponent } from './schedule/jvgame/jvgame.component';
import { DonateComponent } from './donate/donate.component';
import { HomeComponent } from './home/home.component';
import { LinksComponent } from './links/links.component';
import { ReservedseatingComponent } from './reservedseating/reservedseating.component';
import { RosterjvComponent } from './roster/rosterjv/rosterjv.component';
import { RostervarsityComponent } from './roster/rostervarsity/rostervarsity.component';
import { SponsorComponent } from './sponsor/sponsor.component';
import { SponsorlistComponent } from './sponsorlist/sponsorlist.component';
import { VarsitygameComponent } from './schedule/varsitygame/varsitygame.component';
import { VarsitycalendarComponent } from './schedule/varsitycalendar/varsitycalendar.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: {
      title: 'Wilsonville Football',
      description: 'Welcome to the official home of Wilsonville Football. Get news, schedules, and team information here.'
    }
  },
  {
    path: 'about',
    component: AboutComponent,
    data: {
      title: 'Touchdown Club - Wilsonville Football',
      description: 'Learn about the Wilsonville Football Touchdown Club and how you can support the team.'
    }
  },
  {
    path: 'alumni',
    component: AlumniComponent,
    data: {
      title: 'Alumni - Wilsonville Football',
      description: 'Discover Wilsonville Football alumni who took their talents to the next level. Browse our list of former players who competed in college football and continue the Wildcat legacy.'
    }
  },
  {
    path: 'camps',
    component: CampsComponent,
    data: {
      title: 'Camp Schedule - Wilsonville Football',
      description: 'Find information about Wilsonville Football summer camps, schedules, and how to participate.'
    }
  },
  {
    path: 'coachingstaff',
    component: CoachingStaffComponent,
    data: {
      title: 'Coaching Staff - Wilsonville Football',
      description: 'Meet the dedicated coaching staff behind Wilsonville Football and learn about their experience.'
    }
  },
  {
    path: 'donate',
    component: DonateComponent,
    data: {
      title: 'Donate - Wilsonville Football',
      description: 'Support Wilsonville Football by making a donation. Every contribution helps our team invest in the best safety equipment, grow and succeed.'
    }
  },
  {
    path: 'jvcalendar',
    component: JvcalendarComponent,
    data: {
      title: 'JV/Freshman Practice Schedule - Wilsonville Football',
      description: 'Check out the Junior Varsity and Freshman practice schedules for Wilsonville Football.'
    }
  },
  {
    path: 'jvgame',
    component: JvgameComponent,
    data: {
      title: 'JV Game Schedule - Wilsonville Football',
      description: 'View the JV football game schedule, including dates, times, and locations.'
    }
  },
  {
    path: 'jvroster',
    component: RosterjvComponent,
    data: {
      title: 'JV Roster - Wilsonville Football',
      description: 'Meet the Junior Varsity players of Wilsonville Football, including player profiles and stats.'
    }
  },
  {
    path: 'links',
    component: LinksComponent,
    data: {
      title: 'Links - Wilsonville Football',
      description: 'Useful links related to Wilsonville Football, including partners and resources.'
    }
  },
  {
    path: 'reservedseating',
    component: ReservedseatingComponent,
    data: {
      title: 'Reserved Seating - Wilsonville Football',
      description: 'Learn about reserved seating options for Wilsonville Football games and events.'
    }
  },
  {
    path: 'sponsor',
    component: SponsorComponent,
    data: {
      title: 'Become A Sponsor - Wilsonville Football',
      description: 'Become a sponsor of Wilsonville Football and support our team and community.'
    }
  },
  {
    path: 'sponsorlist',
    component: SponsorlistComponent,
    data: {
      title: 'Our Sponsors - Wilsonville Football',
      description: 'See the businesses and individuals who proudly support Wilsonville Football.'
    }
  },
  {
    path: 'varsitycalendar',
    component: VarsitycalendarComponent,
    data: {
      title: 'Varsity Practice Schedule - Wilsonville Football',
      description: 'Get details on the varsity practice schedule for Wilsonville Football.'
    }
  },
  {
    path: 'varsitygame',
    component: VarsitygameComponent,
    data: {
      title: 'Varsity Game Schedule - Wilsonville Football',
      description: 'Find the Wilsonville Varsity Football game schedule, including opponents and locations.'
    }
  },
  {
    path: 'varsityroster',
    //loadChildren: () => import('./roster/roster.module').then(m => m.RosterModule),  //lazy load
    component: RostervarsityComponent,
    data: {
      title: 'Varsity Roster - Wilsonville Football',
      description: 'See the full varsity roster for Wilsonville Football, including player information and positions.'
    }
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
